import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const SplashPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xs" className="container">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <HomeIcon sx={{ fontSize: 100, color: 'primary.main' }} />
        <Typography variant="h4" component="h1" gutterBottom color="text.primary">
          Welcome to Smart Home
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/login')}
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
};

export default SplashPage;
