import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDkuTmS0IEuCQqahdfDwkNUTTJfLRBfOrY",
  authDomain: "smarthome-bz.firebaseapp.com",
  projectId: "smarthome-bz",
  storageBucket: "smarthome-bz.appspot.com",
  messagingSenderId: "496275629541",
  appId: "1:496275629541:web:a75bb0c7edc51294a2bb0c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };