import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Box, Button, Container, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import './LogIn.css';

const LogIn: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isWide, setIsWide] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState<boolean>(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState<string>('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsLoggedIn(true);
      setIsWide(true);
      setErrorMessage(null);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred');
      }
      setDialogOpen(true);
    }
  };

  const handleLogout = async () => {
    await auth.signOut();
    setIsLoggedIn(false);
    setIsWide(false);
    setEmail('');
    setPassword('');
  };

  const handleGoBack = () => {
    navigate('/splash');
  };

  const handlePasswordReset = async () => {
    if (!passwordResetEmail) {
      alert('Please enter your email to reset your password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, passwordResetEmail);
      alert('Password reset email sent!');
      setPasswordResetDialogOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert('An unexpected error occurred');
      }
    }
  };

  return (
    <Container className="login-page-container">
      <Box className={`square ${isWide ? 'wide' : ''}`}>
        {isLoggedIn ? (
          <>
            <Typography variant="h4" component="h1" gutterBottom className="greeting">
              Hello, {auth.currentUser?.email}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogout}
              className={`logout-button ${isWide ? 'wide' : ''}`}
            >
              Logout
            </Button>
          </>
        ) : (
          <Box className="login-box">
            <Typography variant="h5" component="h2" gutterBottom>
              Log In
            </Typography>
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: { backgroundColor: 'white' }
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                style: { backgroundColor: 'white' }
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
              className={`toggle-button ${isWide ? 'wide' : ''}`}
            >
              Log In
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => navigate('/register')}
              className="register-button"
            >
              Register
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 3 }}
              onClick={() => setPasswordResetDialogOpen(true)}
              className="password-reset-button"
            >
              Forgot Password
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 3 }}
              onClick={handleGoBack}
              className={`back-button ${isWide ? 'wide' : ''}`}
            >
              Go Back
            </Button>
          </Box>
        )}
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Wrong Credentials</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The email or password you entered is incorrect.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} className="dialog-button">Go Back</Button>
          <Button onClick={() => setPasswordResetDialogOpen(true)} autoFocus className="dialog-button">
            Forgot Password
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={passwordResetDialogOpen} onClose={() => setPasswordResetDialogOpen(false)}>
        <DialogTitle>Password Recovery</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="reset-email"
            label="Email Address"
            type="email"
            fullWidth
            value={passwordResetEmail}
            onChange={(e) => setPasswordResetEmail(e.target.value)}
            InputProps={{
              style: { backgroundColor: 'white' }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordResetDialogOpen(false)} className="dialog-button">Cancel</Button>
          <Button onClick={handlePasswordReset} autoFocus className="dialog-button">
            Recover Password
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LogIn;
