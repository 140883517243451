import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SplashPage from './pages/SplashPage';
import RegisterPage from './pages/RegisterPage';
import LogIn from './pages/LogIn';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/splash" element={<SplashPage />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/" element={<SplashPage />} />
      </Routes>
    </Router>
  );
};

export default App;
